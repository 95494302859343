<template>
  <a-modal
    title="便民网站"
    :width="800"
    :destroyOnClose="true"
    :confirmLoading="addloading"
    :visible="visible"
    @ok="saveEventApi"
    @cancel="cancelForm"
  >
    <a-form :form="form">
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="网站名称"
        :validateStatus="checkParams.linkNameStatus"
        :help="checkParams.linkNameStatusMsg"
      >
        <a-input
          placeholder="请输入网站名称"
          v-decorator="['linkName', {initialValue: linkItemData.linkName, rules: [{required: true, validator: verifyLinkName, validateTrigger: 'change'}]}]"
        />
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="网址"
        :validateStatus="checkParams.linkTargetStatus"
        :help="checkParams.linkTargetStatusMsg"
      >
        <a-input
          placeholder="请输入网址"
          v-decorator="['linkTarget', {initialValue: linkItemData.linkTarget, rules: [{required: true, validator: verifyLinkTarget, validateTrigger: 'change'}]}]"
        />
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="备注"
      >
        <a-textarea :rows="5" v-model="linkItemData.remark" placeholder="备注信息" />
      </a-form-item>
      <a-form-item
        style="margin-bottom: 0px;"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="图标"
      >
        <a-upload
          :fileList="fileList"
          listType="picture-card"
          :disabled="isImageUpload"
          :beforeUpload="beforeUpload"
          :customRequest="customRequest"
          @preview="handlePreview"
          :remove="removeImage"
          accept="image/*"
        >
          <div>
            <span><a-icon :type="uploading ? 'loading' : 'picture'" /></span>
            <span>上传</span>
          </div>
        </a-upload>
        <a-modal :visible="previewInfo.previewVisible" :title="previewInfo.previewTitle" :footer="null" @cancel="handleCancel">
          <img alt="example" style="width: 100%" :src="previewInfo.previewImage" />
        </a-modal>
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="状态"
      >
        <a-radio-group
          v-model="linkItemData.isEnabled"
          style="width: 100%"
        >
          <a-radio :value="1">启用</a-radio>
          <a-radio :value="0">禁用</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="排序"
      >
        <a-input-number
          :min="0"
          :max="100"
          v-model="linkItemData.linkOrder"
          placeholder="排序"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>

import OSS from 'ali-oss'
import { addImagelibrary, getToken } from '@/api/upload'
import { saveLinkItem } from '@/api/linkitem'

export default {
  name: 'WebsiteInfo',
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      form: this.$form.createForm(this, { name: 'addEventForm' }),
      addloading: false,
      dealCheckStatus: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      loading: false,
      queryParam: {
        id: '',
        keyId: 0,
        status: -1,
        desc: '',
        imageList: []
      },
      previewInfo: {
        previewVisible: false,
        previewTitle: '',
        previewImage: ''
      },
      fileList: [],
      isImageUpload: false,
      uploading: false,
      uploadImageInfo: {
        fileName: '',
        url: '',
        width: 0,
        height: 0,
        size: 0,
        bucket: 'dadaojianqi-syue',
        type: 0,
        transcoding: 0
      },
      tokenInfo: {
        'region': '',
        'AccessKeyId': '',
        'AccessKeySecret': '',
        'SecurityToken': '',
        'bucket': '',
        'domain': ''
      },
      checkParams: {
        linkNameStatus: 'success',
        linkNameStatusMsg: null,
        isLinkName: false,
        linkTargetStatus: 'success',
        linkTargetStatusMsg: null,
        isLinkTarget: false
      },
      linkItemData: {}
    }
  },
  created () {
  },
  methods: {
    initialData (data) {
      this.linkItemData = Object.assign({}, data)
      this.checkParams.linkNameStatus = 'success'
      this.checkParams.linkNameStatusMsg = null
      this.checkParams.isLinkName = true
      this.checkParams.linkTargetStatus = 'success'
      this.checkParams.linkTargetStatusMsg = null
      this.checkParams.isLinkTarget = true
      console.log('linkItemData', this.linkItemData)
      this.getTokenApi()
      this.fileList = this.linkItemData.attachmentsList
      console.log(this.fileList)
    },
    closeSeeInfo () {
      this.visible = false
    },
    cancelForm () {
      this.checkParams.statusStatus = 'success'
      this.checkParams.statusStatusMsg = null
      this.$emit('cancel', 1)
    },
    handleCancel (e) {
      console.log(e)
      this.previewInfo.previewVisible = false
      this.previewInfo.previewImage = ''
      this.previewInfo.previewTitle = ''
    },
    saveEventApi () {
      const _this = this
      _this.linkItemData.linkType = 0
      console.log('linkItemData', _this.linkItemData)
      if (_this.linkItemData.linkName !== '') {
        _this.checkParams.linkNameStatus = 'success'
        _this.checkParams.linkNameStatusMsg = null
        _this.checkParams.isLinkName = true
      } else {
        _this.checkParams.linkNameStatus = 'error'
        _this.checkParams.linkNameStatusMsg = '请输入网站名称'
        _this.checkParams.isLinkName = false
        return false
      }

      if (_this.linkItemData.linkTarget !== '') {
        _this.checkParams.linkTargetStatus = 'success'
        _this.checkParams.linkTargetStatusMsg = null
        _this.checkParams.isLinkTarget = true
      } else {
        _this.checkParams.linkTargetStatus = 'error'
        _this.checkParams.linkTargetStatusMsg = '请输入网址'
        _this.checkParams.isLinkTarget = false
        return false
      }
      saveLinkItem(_this.linkItemData).then((res) => {
        if (res.errorCode === 0) {
          if (_this.linkItemData.keyId > 0) {
            _this.$message.success('编辑成功')
          } else {
            _this.$message.success('添加成功')
          }
          this.$emit('ok', 1)
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    handlePreview (e) {
      this.previewInfo.previewImage = e.url
      this.previewInfo.previewTitle = e.name
      this.previewInfo.previewVisible = true
      console.log('handlePreview', e)
    },
    removeImage (e) {
      console.log('removeImage', e)
      this.fileList.forEach((item, index) => {
        if (item.uid === e.uid) {
          this.fileList.splice(index, 1)
        }
      })

      this.linkItemData.linkIcon = ''
      return true
    },
    getTokenApi () {
      const _this = this
      getToken().then((res) => {
        if (res.errorCode === 0) {
          _this.tokenInfo = res.result
          console.log('tokenInfo', _this.tokenInfo)
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        _this.$message.error('系统错误')
        console.log(err)
      })
    },
    addImagelibraryApi () {
      const _this = this
      addImagelibrary(_this.uploadImageInfo).then((res) => {
        if (res.errorCode === 0) {
          _this.fileList = [
            {
              uid: res.result.id,
              name: res.result.fileName,
              status: 'done',
              url: res.result.url
            }
          ]
          _this.linkItemData.linkIcon = res.result.url
          console.log('fileList', _this.fileList)
          _this.$message.success('上传成功')
          console.log('addImagelibrary', res.result)
        } else {
          _this.$message.error(res.errorMsg)
        }
        _this.isImageUpload = false
        _this.uploading = false
      }).catch((err) => {
        _this.isImageUpload = false
        _this.uploading = false
        _this.$message.error('系统错误')
        console.log(err)
      })
    },
    async customRequest (e) {
      console.log('customRequest', e)
      const _this = this
      const client = new OSS({
        // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
        region: _this.tokenInfo.region,
        // 阿里云账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM用户进行API访问或日常运维，请登录RAM控制台创建RAM用户。
        accessKeyId: _this.tokenInfo.AccessKeyId,
        accessKeySecret: _this.tokenInfo.AccessKeySecret,
        stsToken: _this.tokenInfo.SecurityToken,
        // 填写Bucket名称，例如examplebucket。
        bucket: _this.tokenInfo.bucket,
        secure: true
      })

      const headers = {
        // 指定该Object被下载时的网页缓存行为。
        'Cache-Control': 'no-cache',
        // 指定该Object被下载时的名称。
        'Content-Disposition': _this.uploadImageInfo.fileName,
        // 指定该Object被下载时的内容编码格式。
        'Expires': 1000,
        'Content-Encoding': 'utf-8',
        // 指定Object标签，可同时设置多个标签。
        'x-oss-tagging': 'image=1',
        // 指定初始化分片上传时是否覆盖同名Object。此处设置为true，表示禁止覆盖同名Object。
        'x-oss-forbid-overwrite': 'true'
      }

      const options = {
        // 获取分片上传进度、断点和返回值。
        progress: (p, cpt, res) => {
          console.log(p)
        },
        // 设置并发上传的分片数量。
        parallel: 4,
        // 设置分片大小。默认值为1 MB，最小值为100 KB。
        partSize: 1024 * 1024,
        headers
      }
      console.log(client, options)

      try {
        const result = await client.multipartUpload(_this.uploadImageInfo.fileName, e.file, {
          ...options
        })

        if (result.name) {
          console.log(_this.tokenInfo.domain + result.name)
          _this.uploadImageInfo.url = _this.tokenInfo.domain + result.name
          _this.addImagelibraryApi()
        } else {
          _this.$message.error('上传失败')
          _this.isImageUpload = false
          _this.uploading = false
        }
        console.log(result)
      } catch (e) {
        _this.isImageUpload = false
        _this.uploading = false
        console.log(e)
      }
    },
    beforeUpload (file, fileList) {
      console.log('beforeUpload', file)
      if (this.isImageUpload) {
        this.$message.error('有其他图片正常上传，请稍后在操作')
        return false
      }
      console.log(file)
      var fileArr = file.name.split('.')
      var fileType = fileArr[fileArr.length - 1]

      var myDate = new Date()
      var year = myDate.getFullYear()
      var month = myDate.getMonth() + 1
      var day = myDate.getDate()
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }

      if (day >= 0 && day <= 9) {
        day = '0' + day
      }

      var randomStr = this.getRandomStr(14)
      var keyimg = 'image/' + year + '/' + month + day + '/' + randomStr + '.' + fileType
      console.log(keyimg)
      this.uploadImageInfo.fileName = keyimg
      this.uploadImageInfo.size = Math.ceil(file.size / 1000)
      this.isImageUpload = true
      this.uploading = true
      return true
    },
    getRandomStr (len) {
      var str = ''
      for (var i = 0; i < len; i++) {
        str += Math.random().toString(36).substr(2)
      }
      return str.substr(0, len)
    },
    verifyLinkName (rule, value, callback) {
      const _this = this
      _this.linkItemData.linkName = value.trim()
      if (_this.linkItemData.linkName !== '') {
        _this.checkParams.linkNameStatus = 'success'
        _this.checkParams.linkNameStatusMsg = null
        _this.checkParams.isLinkName = true
      } else {
        _this.checkParams.linkNameStatus = 'error'
        _this.checkParams.linkNameStatusMsg = '请输入网站名称'
        _this.checkParams.isLinkName = false
        callback(new Error('请输入网站名称'))
      }
    },
    verifyLinkTarget (rule, value, callback) {
      const _this = this
      _this.linkItemData.linkTarget = value.trim()
      if (_this.linkItemData.linkTarget !== '') {
        _this.checkParams.linkTargetStatus = 'success'
        _this.checkParams.linkTargetStatusMsg = null
        _this.checkParams.isLinkTarget = true
      } else {
        _this.checkParams.linkTargetStatus = 'error'
        _this.checkParams.linkTargetStatusMsg = '请输入网址'
        _this.checkParams.isLinkTarget = false
        callback(new Error('请输入网址'))
      }
    }
  }
}
</script>

<style lang="less" scoped>
</style>
