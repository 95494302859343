import request from '@/utils/request'
const baseURL = '/baseapi'
const LinkitemApi = {
  LinkitemList: '/admin/linkitem/list',
  LinkItemInfo: '/admin/linkitem/linkItemInfo',
  SaveLinkItem: '/admin/linkitem/saveLinkItem',
  SetLinkStatus: '/admin/linkitem/setStatus'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */

export function linkitemList (parameter) {
  return request({
    baseURL: baseURL,
    url: LinkitemApi.LinkitemList,
    method: 'post',
    data: parameter
  })
}

export function linkItemInfo (parameter) {
  return request({
    baseURL: baseURL,
    url: LinkitemApi.LinkItemInfo,
    method: 'post',
    data: parameter
  })
}

export function saveLinkItem (parameter) {
  return request({
    baseURL: baseURL,
    url: LinkitemApi.SaveLinkItem,
    method: 'post',
    data: parameter
  })
}

export function setLinkStatus (parameter) {
  return request({
    baseURL: baseURL,
    url: LinkitemApi.SetLinkStatus,
    method: 'post',
    data: parameter
  })
}
